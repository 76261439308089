/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:58977f82-1fd0-441c-9462-6b7ad4ebe1a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5JraxkGPQ",
    "aws_user_pools_web_client_id": "3fhah970c9vuqhra30qb04cftk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "demohubs3uploader668d935a2bdc45f1b6b839f83016d882122-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "demohubs3uploader-20230406082430-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2w9eqag8gmr7h.cloudfront.net"
};


export default awsmobile;
